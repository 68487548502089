import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoginRequest, LoginResponse, PasswordResetRequest, PasswordResetResponse } from 'src/app/auth/model/login';
import { RootState } from 'src/app/store/reducers';
import { ClientUserAction } from 'src/app/store/reducers/client-user.reducer';

// this is used by auth.intercepter, do not include AppConfigService as it will cause circular dependency error
@Injectable()
export class LogoutService {

  constructor(private store: Store<RootState>, private router: Router) { }

  public clearToken(): void { localStorage.clear(); }

  public getToken(): string { return localStorage.getItem('token'); }
  public logOut(errorMessage: string): void {
    this.store.dispatch(ClientUserAction.ClearClientUser());
    this.clearToken();
    // if (errorMessage != null) this.setError(errorMessage);
    // this.document.location.href = this.APPL_URL;
    // AppActions.setSelectedTabs([]);
    // this.document.location.href = AppConfigService.appConfig.api.applUrl;
    if (errorMessage != null) {
      this.router.navigate(['/auth/login', { message: errorMessage }], { replaceUrl: true });
    }
  }
}
