import { Injectable } from '@angular/core';
import { DefaultHttpUrlGenerator, EntityHttpResourceUrls, HttpResourceUrls, normalizeRoot, Pluralizer } from '@ngrx/data';
import { AppConfigService } from 'src/app/app-config.service';

const ENTITY_URL: { [entityName: string]: string } = {
  ["dashlet"]: "agent", 
  ["dashboard"]: "agent", 
  ["payeeDashlet"]: "agent",
  ["paymentSummary"]: "agent", 
  ["paymentDetail"]: "agent", 
  ["report"]: "agent", 

  ["payeeEntity"]: "manager", 
  ["transactionEntity"]: "manager", 
  ["referenceEntity"]: "manager", 
  ["territoryEntity"]: "manager", 

  ["clientEntity"]: "manager", 
  ["searchView"]: "manager", 
  
  ["compensationPackage"]: "manager", 
  ["compensationElement"]: "manager", 
  ["compensationAssignment"]: "manager", 

  ["payeeNodeContent"]: "manager",
  ["rollType"]: "manager",
};

@Injectable()
export class AppHttpUrlGenerator extends DefaultHttpUrlGenerator {
  /**
   * Known single-entity and collection resource URLs for HTTP calls.
   * Generator methods returns these resource URLs for a given entity type name.
   * If the resources for an entity type name are not know, it generates
   * and caches a resource name for future use
   */
  // protected knownHttpResourceUrls: EntityHttpResourceUrls = {};

  constructor(pluralizer: Pluralizer) {
      super(pluralizer);
      console.log("::init");
  }

  /**
   * Get or generate the entity and collection resource URLs for the given entity type name
   * @param entityName {string} Name of the entity type, e.g, 'Hero'
   * @param root {string} Root path to the resource, e.g., 'some-api`
   */
  protected getResourceUrls(entityName: string, root: string): HttpResourceUrls {
    console.log("::getResourceUrls: entityName=" + entityName + " root=" + root + " baseUrl=" + AppConfigService.appConfig.api.baseUrl);
    // let resourceUrls = this.knownHttpResourceUrls[entityName];
    // if (!resourceUrls) {
    //   const nRoot = normalizeRoot(root);
    //   resourceUrls = {
    //     entityResourceUrl: `${nRoot}/${entityName}/`.toLowerCase(),
    //     collectionResourceUrl: `${nRoot}/${this.pluralizer.pluralize(
    //       entityName
    //     )}/`.toLowerCase(),
    //   };
    //   this.registerHttpResourceUrls({ [entityName]: resourceUrls });
    // }
    // return resourceUrls;
    return super.getResourceUrls(entityName, AppConfigService.appConfig.api.baseUrl);
  }

  /**
   * Create the path to a single entity resource
   * @param entityName {string} Name of the entity type, e.g, 'Hero'
   * @param root {string} Root path to the resource, e.g., 'some-api`
   * @returns complete path to resource, e.g, 'some-api/hero'
   */
  entityResource(entityName: string, root: string): string {
    let resourceUrl = ENTITY_URL[entityName];
    console.log("::entityResource: entityName=" + entityName + " root=" + root + " resourceUrl=" + resourceUrl + " baseUrl=" + AppConfigService.appConfig.api.baseUrl);
    if (resourceUrl) {
      const nRoot = normalizeRoot(AppConfigService.appConfig.api.baseUrl);
      // return `${nRoot}/${resourceUrl}/data/${entityName}/`.toLowerCase();
      return `${nRoot}/${resourceUrl}/data/${entityName}/`;
    }
    return super.getResourceUrls(entityName, AppConfigService.appConfig.api.baseUrl).entityResourceUrl;
  }

  /**
   * Create the path to a multiple entity (collection) resource
   * @param entityName {string} Name of the entity type, e.g, 'Hero'
   * @param root {string} Root path to the resource, e.g., 'some-api`
   * @returns complete path to resource, e.g, 'some-api/heroes'
   */
  collectionResource(entityName: string, root: string): string {
    let resourceUrl = ENTITY_URL[entityName];
    console.log("::collectionResource: entityName=" + entityName + " root=" + root + " resourceUrl=" + resourceUrl + " baseUrl=" + AppConfigService.appConfig.api.baseUrl);
    if (resourceUrl) {
      const nRoot = normalizeRoot(AppConfigService.appConfig.api.baseUrl);
      // return `${nRoot}/${resourceUrl}/data/${entityName}/`.toLowerCase();
      return `${nRoot}/${resourceUrl}/data/${entityName}/`;
    }
    return super.getResourceUrls(entityName, AppConfigService.appConfig.api.baseUrl).collectionResourceUrl;
  }

  /**
   * Register known single-entity and collection resource URLs for HTTP calls
   * @param entityHttpResourceUrls {EntityHttpResourceUrls} resource urls for specific entity type names
   * Well-formed resource urls end in a '/';
   * Note: this method does not ensure that resource urls are well-formed.
   */
  registerHttpResourceUrls(entityHttpResourceUrls: EntityHttpResourceUrls): void {
    console.log("::registerHttpResourceUrls1 ", entityHttpResourceUrls);
    console.log("::registerHttpResourceUrls2 ", this.knownHttpResourceUrls);

    // let entityHttpResourceUrls2: EntityHttpResourceUrls = {
    //     "TransactionEntity": { entityName: "TransactionEntity", root: }
    // }
    // entityHttpResourceUrls2
    super.registerHttpResourceUrls(entityHttpResourceUrls);
  }
}