import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { reducers, metaReducers } from './reducers';
import { AppHttpUrlGenerator } from './app-http-url-generator';
import { DefaultDataServiceConfig, EntityDataModule, EntityDefinitionService, EntityMetadataMap, HttpUrlGenerator } from '@ngrx/data';
import { ENTITY_CLIENT_ENTITY } from '../core/model/client-entity';
import { ClientEntityService } from '../core/service/client-entity.service';

// 2023-05-10: this entityMetadata contains global entity data for both manager and agent and configure
const entityMetadata: EntityMetadataMap = {
    [ENTITY_CLIENT_ENTITY]: {},
};
const defaultDataServiceConfig: DefaultDataServiceConfig = {
    // root: AppConfigService.appConfig.api.baseUrl,
    timeout: 30000, // request timeout, 30 seconds
  };
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers, 
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
    // AppEntityStoreModule,
  ],
  providers: [
    ClientEntityService,
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: HttpUrlGenerator, useClass: AppHttpUrlGenerator }
  ],
})
export class AppStoreModule {
    constructor(private eds: EntityDefinitionService) {
        eds.registerMetadataMap(entityMetadata);
    }
 }
