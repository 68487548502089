import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { LoginGuard } from './core/guard/login.guard';

const routes: Routes = [

  { path: 'manager', loadChildren: () => import('./application/manager/manager.module').then(m => m.ManagerModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'configure', loadChildren: () => import('./application/configure/configure.module').then(m => m.ConfigureModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { reuse: true } },
  { path: 'agent', loadChildren: () => import('./application/agent/agent.module').then(m => m.AgentModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [LoginGuard], canActivateChild: [LoginGuard] },
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
