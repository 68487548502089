import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-18_June_2022_[v2]_MTY1NTUwNjgwMDAwMA==8d3070f2fab0efd56a6e2fc1596c2bb7");
LicenseManager.setLicenseKey("CompanyName=The Bank of Nova Scotia,LicensedGroup=QTS/ETF/ZEUS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=6,LicensedProductionInstancesCount=0,AssetReference=AG-030303,SupportServicesEnd=11_January_2024_[v2]_MTcwNDkzMTIwMDAwMA==794762d7fdb8a681bdcc096ecca5e78b");
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
