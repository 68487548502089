import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppStoreModule } from './store/app-store.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './core/provider/custom.reuse.strategy';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GridsterModule } from 'angular-gridster2';
// import { SplashScreenComponent } from './splash-screen/splash-screen.component';
// import { TuiLineClampModule } from '@taiga-ui/kit';
// import { TuiRootModule } from '@taiga-ui/core';
// const modules: any[] = [AppStoreModule, CoreModule, MatProgressSpinnerModule];
// import { TooltipModule } from '@syncfusion/ej2-angular-popups';
// import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { AppConfigService } from './app-config.service';
import { AppConfigModule } from './app-config.module';
// import { NgApexchartsModule } from 'ng-apexcharts';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DatePipe } from '@angular/common';
import {
  popperVariation,
  provideTippyConfig,
  TippyDirective,
  tooltipVariation,
  withContextMenuVariation
} from '@ngneat/helipopper';
import {
  provideHighlightOptions,
  Highlight,
  HighlightAuto,
} from 'ngx-highlightjs';

const modules: any[] = [AppConfigModule, AppStoreModule, CoreModule, MatProgressSpinnerModule];
let zIndex = 99999;

function getZIndex() {
  return zIndex++;
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ...modules,
    GridsterModule,
    // NgApexchartsModule,
    // TuiRootModule,
  ],
  providers: [
    DatePipe,
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
    Title,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      zIndexGetter: getZIndex,
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        // menu: {
        //   ...popperVariation,
        //   appendTo: 'parent',
        //   arrow: false,
        //   offset: [0, 0]
        // },
        // contextMenu: withContextMenuVariation(popperVariation),
        // popperBorder: {
        //   ...popperVariation,
        //   theme: 'light-border'
        // }
      }
    }),
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'), // Optional, add line numbers if needed
      languages: {
        typescript: () => import('highlight.js/lib/languages/typescript'),
      },
      // themePath: 'path-to-theme.css' // Optional, useful for dynamic theme changes
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log("::init");
  }
}
