import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {

  private handlers: {[key: string]: DetachedRouteHandle} = {};

  constructor() {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    this.print("reuse::shouldDetach", route);
    // console.log("reuse::shouldDetach:  route=", route);
    // console.log("reuse::shouldDetach:  route=", !!route.component, route['_routerState'].url, route.routeConfig?.path, route.routeConfig?.component?.name, route.routeConfig?.children);
    if (this.handlers["/manager"]) {
      if (String(route['_routerState'].url).startsWith("/agent")) return false;
    }
    return true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.print("reuse::store", route);
    // console.log("reuse::store:  route=", route);
    // console.log("reuse::store:  route=", !!route.component, route['_routerState'].url, route.routeConfig?.path, route.routeConfig?.component?.name, route.routeConfig?.children);
    this.handlers[route['_routerState'].url] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    this.print("reuse::shouldAttach", route);
    // console.log("reuse::shouldAttach:  route=", route);
    console.log("reuse::shouldAttach: this.handlers=", this.handlers);
    console.log("reuse::shouldAttach: this.handlers has manager=", this.handlers["/manager"]);
    // console.log("reuse::shouldAttach:  route=", !!route.component, route['_routerState'].url, route.routeConfig?.path, route.routeConfig?.component?.name, route.routeConfig?.children);
    if (this.handlers["/manager"]) {
      if (String(route['_routerState'].url).startsWith("/agent")) return false;
    }
    return !!this.handlers[route['_routerState'].url] && !!route.component;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    this.print("reuse::retrieve", route);
    // console.log("reuse::retrieve:  route=", route);
    // console.log("reuse::retrieve:  route=", !!route.component, route['_routerState'].url, route.routeConfig?.path, route.routeConfig?.component?.name, route.routeConfig?.children);
    if (!route.component) return null;
    return this.handlers[route['_routerState'].url];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    // console.log("reuse::shouldReuseRoute:  future=", future['_routerState'].url, future.routeConfig?.path, future.routeConfig?.component?.name, future.routeConfig?.children);
    // console.log("reuse::shouldReuseRoute: current=", current['_routerState'].url, current.routeConfig?.path, current.routeConfig?.component?.name, current.routeConfig?.children);
    this.print("reuse::shouldReuseRoute:  future", future);
    this.print("reuse::shouldReuseRoute:  current", current);
    
    if (String(future['_routerState'].url).startsWith("/agent") && String(current['_routerState'].url).startsWith("/manager")) {
      console.log("reuse::shouldReuseRoute: []=", future['_routerState']);
      console.log("returning false");
      return false;
    }
    if (future.routeConfig === current.routeConfig) {
      if (future.params.id === current.params.id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  print(prefix: string, route: ActivatedRouteSnapshot) {
    // console.log(prefix + ": url=" + route['_routerState'].url + " handle=" + this.handlers[route['_routerState'].url] + " component=" + route.component?.name + " routeConfig.path=" + route.routeConfig?.path + " routeConfig.component=" + route.routeConfig?.component?.name + " hasChildren=" + (route.routeConfig?.children != null) + " reuse=" + route.data.reuse);
    // console.log(prefix + ": handle=" + this.handlers[route['_routerState'].url]);
  }
}
