import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './service/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { CookieService } from './service/cookie-service';
import { StoreModule } from '@ngrx/store';
import * as fromCommon from './store/reducers';
import { LogoutService } from './service/logout.service';
// import { ClientEntityService } from './service/client-entity.service';

const services = [
  AuthService,
  LogoutService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  CookieService,
//   ClientEntityService
];

const modules: any[] = [ HttpClientModule ];

const guards = [ AuthGuard, LoginGuard ];

@NgModule({
  declarations: [],
  imports: [ ...modules, StoreModule.forFeature(fromCommon.commonFeatureKey, fromCommon.reducers, { metaReducers: fromCommon.metaReducers }) ],
  providers: [ ...services, ...guards ]  
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
        throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
