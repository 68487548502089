import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { CookieService } from 'src/app/core/service/cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogoutService } from '../service/logout.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private logoutService: LogoutService, private cookieService: CookieService) {}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cookie = this.cookieService.getAllCookie();
    // console.log("::intercept: cookie=" + cookie);
    const token = this.logoutService.getToken();
    // console.log("::intercept: token=" + token);
    const headers = httpRequest.headers.getAll;
    // console.log("::intercept: headers=", headers);
    const request = token ? httpRequest.clone({
      headers: httpRequest.headers
        .set('Authorization', 'Bearer ' + token)
        .set('Content-Type', 'application/json')
      }) : httpRequest.clone({
      headers: httpRequest.headers
        .set('Test-Cookie', cookie)
      });

    // request.headers.set('Cookie', cookie);
    const headers2  = request.headers.getAll;
    // console.log("::intercept: headers2=", headers2);


    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse)) {
          return;
        }
      }, 
      (err: any) => {
        console.log('AuthInterceptor::intercept: intercepted error: ', err);

        if (err instanceof HttpErrorResponse && err.status === 401) {
          console.log("AuthInterceptor::intercept: logging out");
          const message = (err.error.error === "tokenExpired" ? "Session expired, please log in again" : err.error.message);
          this.logoutService.logOut(message);
        }

        return throwError(err);
      }
    ));
  }
}
