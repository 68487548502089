import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppConfigService } from './app-config.service';

// const appInitializerFn = (appConfigService: AppConfigService) => {
//   return () => appConfigService.loadConfig();
// };

@NgModule(
//   {
//   providers: [
//     {
//       provide: APP_INITIALIZER,
//       useFactory: appInitializerFn,
//       deps: [AppConfigService],
//       multi: true
//     }
//   ]
// }
)
export class AppConfigModule {
  constructor(appConfigService: AppConfigService) {
    console.log("::init");
    appConfigService.loadConfig();
  }
 }
